import {EBooleanText, EDataFieldTypes} from '../app.enums';
import {DropdownItem} from '../models/ui/dropdown-item.model';
import {IDataFieldsModel} from '../models/interfaces/datafields-model.interface';
import {FormDetailModel} from '../models/api/form.model';
import {EColumnDataType, ITableColumn} from '@relayter/rubber-duck';
import {RLDatePipe} from '../pipes/rl-date.pipe';
import {IDataFieldTableColumn} from '../models/interfaces/datafield-table-column.interface';
import {DataFieldModel} from '../models/api/data-field.model';
import {VariantModel} from '../models/api/variant.model';
import {AppConstants} from '../app.constants';
import {ARPagedResponseDataModel, ARUtils} from '@relayter/core';
import {BooleanDisplayPipe} from '../pipes/boolean-display.pipe';
import {CustomWorkflowOptionModel} from '../models/api/custom-workflow-option.model';
import {DataFieldDataTypeModel} from '../models/api/data-field-data-type.model';
import {FormatFunction} from '@relayter/rubber-duck/lib/pipes/property.pipe';
import {inject, Injectable} from '@angular/core';
import {DataFieldsApiService} from '../api/services/data-fields.api.service';
import {CustomWorkflowFilterOptionModel} from '../models/api/custom-workflow-filter-option.model';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DataFieldsComponentUtil {
    private dataFieldsApiService: DataFieldsApiService = inject(DataFieldsApiService);

    /**
     * Converts string or array value to DropdownItems
     * for dataFields of type EDataFieldTypes.ENUM && EDataFieldTypes.BOOLEAN && EDataFieldTypes.LIST
     */
    public static dataFieldsFromModelData(formConfig: FormDetailModel,
                                          item: IDataFieldsModel,
                                          variants: VariantModel[]): Record<string, any> {
        const groupedFields = {}; // key is EDataFieldTypes, value is an array of dataField names
        const fieldsWithVariantsEnabled = [];
        Object.values(EDataFieldTypes).forEach((dataType) => groupedFields[dataType] = []);
        const multiSelectFields = [];
        formConfig.rows.forEach((row) => {
            row.fields.forEach((formField) => {
                const dataType = formField.dataField.dataType.type;
                groupedFields[dataType].push(formField.dataField.fieldName);
                if (dataType === EDataFieldTypes.ENUM && formField.dataField.dataType.enumeration.multiSelect) {
                    multiSelectFields.push(formField.dataField.fieldName); // Just because they are special
                }
                if (formField.dataField.enableVariants) fieldsWithVariantsEnabled.push(formField.dataField.fieldName);
            });
        });
        const dataFields = {};
        Object.keys(item.dataFields).forEach((property) => {
            const fieldValue = item.dataFields[`${property}`];
            if (fieldsWithVariantsEnabled.includes(property) && variants.length > 0) {
                variants.forEach((variant) => {
                    const value = ARUtils.isObject(fieldValue) ? fieldValue[`${variant.key}`] : fieldValue; // migrate the value on the fly
                    dataFields[`${property}.${variant.key}`] =
                        DataFieldsComponentUtil.getFormattedValue(value, property, groupedFields, multiSelectFields);
                });
            } else {
                dataFields[`${property}`] = DataFieldsComponentUtil.getFormattedValue(fieldValue, property, groupedFields, multiSelectFields);
            }
        });
        return dataFields;
    }

    private static getFormattedValue(fieldValue: any, property: string, groupedFields: Record<string, any>, multiSelectFields: string[]): any {
        if (groupedFields[EDataFieldTypes.BOOLEAN].includes(property)) {
            return new DropdownItem(fieldValue ? EBooleanText.TRUE : EBooleanText.FALSE, fieldValue);
        }
        if (groupedFields[EDataFieldTypes.LIST].includes(property) || multiSelectFields.includes(property)) {
            return ((fieldValue || []) as string[]).map((value) => new DropdownItem(value, value));
        }
        if (groupedFields[EDataFieldTypes.ENUM].includes(property)) {
            return new DropdownItem(fieldValue as string, fieldValue);
        }
        return fieldValue;
    }

    public static getBodyForDataFields(dataFields: Record<string, any>, forPatch: boolean): Record<string, any> {
        const formattedFields = {};
        Object.keys(dataFields).forEach((field) => {
            const value = forPatch
                ? DataFieldsComponentUtil.getFieldValueForPatchBody(dataFields, field)
                : DataFieldsComponentUtil.getFieldValueForPostBody(dataFields, field);

            // check if the data field contains nested properties(variants).
            // convert current value to variants if saved for the first time after setting up variants
            const fieldNames = field.split('.');
            if (fieldNames.length === 2) {
                if (!formattedFields[`${fieldNames[0]}`]) formattedFields[`${fieldNames[0]}`] = {};
                formattedFields[`${fieldNames[0]}`][`${fieldNames[1]}`] = value;
            } else {
                formattedFields[`${field}`] = value;
            }
        });
        return formattedFields;
    }

    public static serializeDataFieldsForApiBody(dataFieldsObject: Record<string, any>, dataFields: DataFieldModel[]): Record<string, any> {
        const serializedDataFields = {};
        for (const dataFieldKey of Object.keys(dataFieldsObject)) {
            const dataField = dataFields.find(contextDataField => contextDataField.fieldName === dataFieldKey);
            if (!dataField) {
                throw new Error(`No data field found for '${dataFieldKey}'`);
            }

            serializedDataFields[dataField.name] = dataFieldsObject[dataFieldKey];
        }

        return serializedDataFields;
    }

    private static getFieldValueForPostBody(dataFields: Record<string, any>, field: string): any {
        const fieldValue = dataFields[`${field}`];

        if (Array.isArray(fieldValue)) {
            if (fieldValue.length > 0) { // only set the array value when the array has items
                return fieldValue.map((item) => item.value);
            }
        } else if (fieldValue instanceof DropdownItem) {
            return fieldValue.getValue();
        } else if (fieldValue !== null && fieldValue !== undefined) {
            return fieldValue;
        }
    }

    private static getFieldValueForPatchBody(dataFields: Record<string, any>, field: string): any {
        const fieldValue = dataFields[`${field}`];

        if (Array.isArray(fieldValue)) {
            return fieldValue.length > 0 ? fieldValue.map((item) => item.value) : null;
        } else if (fieldValue instanceof DropdownItem) {
            return fieldValue.getValue();
        } else {
            return fieldValue !== null && fieldValue !== undefined && fieldValue !== '' ? fieldValue : null;
        }
    }

    public getDataFieldsColumnList(dataFields: IDataFieldTableColumn[], columnConfig: CustomWorkflowOptionModel,
                                   variantKey?: string): ITableColumn[] {
        if (dataFields?.length && !!columnConfig && Array.isArray(columnConfig.value)) {
            const regex = new RegExp('.dataFields.([a-zA-Z\\d\-_]*)(\.?)(.*)');
            const workflowDataColumns: IDataFieldTableColumn[] = columnConfig.value.map((obj) => {
                const column = {
                    name: obj.displayName,
                    fieldName: obj.property
                } as IDataFieldTableColumn;

                // Format variant enabled, date and boolean data fields correctly
                const dataFieldsMatch = obj.property.match(regex);
                const dataField = dataFields.find(field => dataFieldsMatch && field.fieldName === dataFieldsMatch[1]);
                if (dataField) {
                    column.dataType = dataField.dataType;
                    column.enableVariants = dataField.enableVariants;
                    column._id = dataField._id;
                } else {
                    column.dataType = {type: EDataFieldTypes.STRING} as DataFieldDataTypeModel;
                }

                // Page type is a property defined for the publication item model, representing the numberOfPages
                // To make sorting and paging work, set correct sortProperty and dataType
                if (obj.property === 'pageType') {
                    column.sortProperty = 'numberOfPages';
                    column.dataType =  {type: EDataFieldTypes.NUMBER} as DataFieldDataTypeModel;
                }

                return column;
            });

            return this.getDataFieldsColumnSelection(workflowDataColumns, false, variantKey, '');
        }

        return [];
    }

    public getDataFieldsColumnSelection(dataFields: IDataFieldTableColumn[], editable: boolean = false,
                                        variantKey?: string, prefix = 'dataFields.'): ITableColumn[] {
        return dataFields.map((dataField) => {

            const tableColumn = {
                title: dataField.name,
                key: `${prefix}${dataField.fieldName}`,
                sortProperty: (dataField.sortProperty ? `${prefix}${dataField.sortProperty}` : `${prefix}${dataField.fieldName}`),
                sortDuplicates: true,
                dataType: DataFieldsComponentUtil.mapDataTypeToColumnDataType(dataField.dataType.type)
            } as ITableColumn;

            if ((dataField.dataType.type === EDataFieldTypes.ENUM && dataField.dataType.enumeration.multiSelect) ||
                dataField.dataType.type === EDataFieldTypes.LIST) {
                tableColumn.format = (val?: string[]) => val?.length ? val.join(', ') : '';

                // Not (yet) possible to do cursor paging
                delete tableColumn.sortProperty;
            }

            if (dataField.dataType.type === EDataFieldTypes.BOOLEAN) {
                tableColumn.format = (value) => BooleanDisplayPipe.transform(value);
            }

            if (dataField.dataType.type === EDataFieldTypes.DATE) {
                tableColumn.format = (val) => RLDatePipe.format(val, RLDatePipe.dateFormats.DEFAULT);
            }

            if (editable &&
                [EDataFieldTypes.STRING, EDataFieldTypes.NUMBER, EDataFieldTypes.DATE,
                    EDataFieldTypes.BOOLEAN, EDataFieldTypes.ENUM, EDataFieldTypes.LIST].includes(dataField.dataType.type)) {

                tableColumn.editable = true;

                if (dataField.dataType.type === EDataFieldTypes.ENUM) {
                    tableColumn.options = dataField.dataType.enumeration.items;
                    tableColumn.multiselect = dataField.dataType.enumeration.multiSelect;
                }

                if (dataField.dataType.type === EDataFieldTypes.LIST ||
                    (dataField.dataType.type === EDataFieldTypes.STRING && dataField.enableAutocomplete)) {
                    tableColumn.search = (search) =>
                        this.dataFieldsApiService.getDataFieldValues(dataField?._id, {}, null, null, search, variantKey)
                            .pipe(
                                map((result: ARPagedResponseDataModel<CustomWorkflowFilterOptionModel>) => result.items.map(item => item.key)),
                                take(1)
                            );
                }
            }

            if (dataField.enableVariants) {
                tableColumn.headerIcon = AppConstants.ICONS.VARIANT;
                tableColumn.headerIconColor = AppConstants.VARIANT_ICON_INFO_COLOR;
                tableColumn.format = DataFieldsComponentUtil.getDataFieldFormatter(tableColumn.format, variantKey);

                if (variantKey) {
                    if (tableColumn.sortProperty) tableColumn.sortProperty = `${tableColumn.sortProperty}.${variantKey}`;
                    tableColumn.variantKey = variantKey;
                }
            }

            return tableColumn;
        });
    }

    public static getDataFieldFormatter(orgFormatter: FormatFunction, variantKey: string): FormatFunction {
        return (val) => {
            if (ARUtils.isObject(val)) {
                if (variantKey) {
                    return orgFormatter ? orgFormatter(val[variantKey]) : val[variantKey];
                } else {
                    return Object.values(val).map(v => orgFormatter ? orgFormatter(v) : v).toString();
                }
            }

            return orgFormatter ? orgFormatter(val) : val;
        };
    }

    public static getTypeFormatter(dataType: EDataFieldTypes, dateFormat?: Record<string, any>): FormatFunction {
        switch (dataType) {
            case EDataFieldTypes.DATE:
                return (val) => RLDatePipe.format(val, dateFormat || RLDatePipe.dateFormats.DEFAULT);
            case EDataFieldTypes.BOOLEAN:
                return (val) => BooleanDisplayPipe.transform(val);
            case EDataFieldTypes.ENUM:
            case EDataFieldTypes.LIST:
                return (val) => Array.isArray(val) ? val.join(', ') : val;
        }
    }

    private static mapDataTypeToColumnDataType(dataType: string): EColumnDataType {
        switch (dataType) {
            case EDataFieldTypes.STRING:
                return EColumnDataType.STRING;
            case EDataFieldTypes.NUMBER:
                return EColumnDataType.NUMBER;
            case EDataFieldTypes.DATE:
                return EColumnDataType.DATE;
            case EDataFieldTypes.BOOLEAN:
                return EColumnDataType.BOOLEAN;
            case EDataFieldTypes.ENUM:
                return EColumnDataType.ENUM;
            case EDataFieldTypes.LIST:
                return EColumnDataType.LIST;
            default:
                return;
        }
    }

    public static transformDataFieldValue(value: any, field: DataFieldModel): string | number {
        if (value === null || value === undefined) return value;

        switch (field.dataType.type) {
            case EDataFieldTypes.BOOLEAN:
                value = BooleanDisplayPipe.transform(value);
                break;
            case EDataFieldTypes.ENUM:
                value = field.dataType.enumeration.multiSelect ? value.join(', ') : value;
                break;
            case EDataFieldTypes.LIST:
                value = value.join(', ');
                break;
            case EDataFieldTypes.DATE:
                value = RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT);
                break;
        }
        return value;
    }
}
